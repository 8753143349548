import Vue from 'vue';

// v-scroll: Trigger rAF throttled callback on scroll
// Passes current scroll position to callback
// Event listener removed if callback returns truthy value
Vue.directive('scroll', {
  bind(el, binding) {
    // Check passive event support
    let supportsPassive = false;

    try {
      const opts = Object.defineProperty({}, 'passive', {
        get() {
          supportsPassive = true;
          return supportsPassive;
        },
      });

      window.addEventListener('testPassive', null, opts);
      window.removeEventListener('testPassive', null, opts);
    } catch (e) {
      supportsPassive = false;
    }

    // Get scroll position
    const scrollTop = () => {
      return (
        window.scrollY ||
        (document.documentElement.clientHeight
          ? document.documentElement.scrollTop
          : document.body.scrollTop) ||
        0
      );
    };

    // Throttle callback
    let requestId = null;

    el.vScroll = () => {
      if (requestId === null) {
        requestId = window.requestAnimationFrame(() => {
          requestId = null;

          // Trigger callback, unbind from scroll if thruthy value returned
          if (binding.value(scrollTop())) {
            window.removeEventListener('scroll', el.vScroll);
            delete el.vScroll;
          }
        });
      }
    };

    // Bind to scroll
    window.addEventListener(
      'scroll',
      el.vScroll,
      supportsPassive ? { passive: true } : false
    );

    // Trigger callback
    el.vScroll();
  },
  unbind(el) {
    window.removeEventListener('scroll', el.vScroll);
    delete el.vScroll;
  },
});
