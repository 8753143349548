import { getField, updateField } from 'vuex-map-fields';

const defaults = {
  is_resident: null,
  is_adult: null,
  name_first: '',
  name_middle: '',
  name_last: '',
  address_street: '',
  address_suburb: '',
  address_state: '',
  address_postcode: '',
  user_agent: '',
  device_type: '',
  referral_charity: '',
  has_partner: false,
};

export const state = () => ({
  email: '',
  meta: Object.assign({}, defaults),
});

export const getters = {
  getField,
};

export const mutations = {
  updateField,
  meta(state, value) {
    state.meta = value;
  },
};

export const actions = {
  resetSignup({ commit }) {
    commit('meta', Object.assign({}, defaults));
  },
};
