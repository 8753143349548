import Vue from 'vue';
import Zendesk from '@dansmaculotte/vue-zendesk';

Vue.use(Zendesk, {
  key: process.env.ZENDESK_KEY,
  disabled: false,
  hideOnLoad: false,
  settings: {
    webWidget: {
      chat: {
        suppress: false,
        departments: {
          enabled: [],
        },
      },
    },
  },
});
