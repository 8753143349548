export const state = () => ({
  showUserPanel: false,
});

export const getters = {
  showUserPanel: (state) => state.showUserPanel,
};

export const mutations = {
  showUserPanel(state, value) {
    state.showUserPanel = value;
  },
};

export const actions = {
  setShowUserPanel({ commit }, value) {
    commit('showUserPanel', value);
  },
};
