export default {
  setEndOfLifeCategories(state, data) {
    const endOfLife = data.reduce(
      (endOfLife, category) => {
        const id = category.id;
        const parentCategoryId = category.parentCategoryId;
        endOfLife.categories[id] = { ...category };
        endOfLife.slugsToIds[category.slug] = id;
        if (category.parentCategoryId) {
          if (endOfLife.children[parentCategoryId]) {
            endOfLife.children[parentCategoryId].subcategories.push({
              ...endOfLife.categories[id],
            });
          } else {
            endOfLife.children[id] = { parentCategoryId, subcategories: [] };
          }
        } else {
          endOfLife.tree.push({ ...category, subcategories: [] });
        }
        return endOfLife;
      },
      { categories: {}, slugsToIds: {}, children: {}, tree: [] }
    );

    Object.entries(endOfLife.children).forEach(([key, value]) => {
      if (endOfLife.children[value.parentCategoryId]) {
        endOfLife.children[value.parentCategoryId].subcategories.push({
          ...endOfLife.categories[key],
        });
        delete endOfLife.children[key];
      }
    });

    Object.entries(endOfLife.children).forEach(([key, value]) => {
      const child = {
        ...endOfLife.categories[key],
        subcategories: value.subcategories.sort(
          (currentCategory, nextCategory) =>
            currentCategory.categoryOrder - nextCategory.categoryOrder
        ),
      };
      const parent = { ...endOfLife.categories[child.parentCategoryId] };
      const parentIndex = endOfLife.tree.findIndex((p) => {
        return p.id === parent.id;
      });
      endOfLife.tree[parentIndex].subcategories.push({ ...child });
    });

    state.endOfLifeCategories = { ...endOfLife.categories };
    state.endOfLifeCategorySlugsToIds = { ...endOfLife.slugsToIds };
    state.endOfLifeCategoryTree = endOfLife.tree;
  },
  setEndOfLifeCategoryItemCounts(state, data) {
    const endOfLifeCategoryItemCounts = {};
    data.forEach((category) => {
      endOfLifeCategoryItemCounts[category.id] = category.count;
    });
    state.endOfLifeCategoryItemCounts = endOfLifeCategoryItemCounts;
  },
  increaseEndOfLifeCategoryItemCount(state, categoryId) {
    state.endOfLifeCategoryItemCounts[categoryId] =
      state.endOfLifeCategoryItemCounts[categoryId] + 1;
  },
  decreaseEndOfLifeCategoryItemCount(state, categoryId) {
    state.endOfLifeCategoryItemCounts[categoryId] =
      state.endOfLifeCategoryItemCounts[categoryId] - 1;
  },
};
