export const state = () => ({
  showGlossaryPanel: false,
});

export const getters = {
  showGlossaryPanel: (state) => state.showGlossaryPanel,
};

export const mutations = {
  showGlossaryPanel(state, value) {
    state.showGlossaryPanel = value;
  },
};

export const actions = {
  setShowGlossaryPanel({ commit }, value) {
    commit('showGlossaryPanel', value);
  },
};
