import BJSON from 'buffer-json';
import { saveAs } from 'file-saver';

const downloadPdf = (buffer, filename = 'download.pdf') => {
  const data = BJSON.parse(buffer);
  const blob = new Blob([data], {
    type: 'application/pdf',
  });

  saveAs(blob, filename);
};

export default (ctx, inject) => {
  inject('downloadPdf', downloadPdf);
};
