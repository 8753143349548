import GET_ALL_END_OF_LIFE_CATEGORIES from '@/graphql/queries/GetAllEndOfLifeCategories';
import GET_END_OF_LIFE_CATEGORIES_WITH_ITEM_COUNT_BY_USER_ID from '@/graphql/queries/GetEndOfLifeCategoriesWithItemCountByUserId';

export default {
  async getEndOfLifeCategories({ state, commit }, forceFetch) {
    if (forceFetch || !state.endOfLifeCategoryTree.length) {
      const {
        data: {
          getAllEndOfLifeCategories: { endOfLifeCategories },
        },
      } = await this.app.apolloProvider.defaultClient.query({
        fetchPolicy: 'no-cache',
        query: GET_ALL_END_OF_LIFE_CATEGORIES,
        variables: {
          type: 'vault',
        },
      });
      commit('setEndOfLifeCategories', endOfLifeCategories);
    }
  },
  async getEndOfLifeCategoryItemCounts({ rootState, commit }) {
    const {
      data: {
        getEndOfLifeCategoriesWithItemCountByUserId: { endOfLifeCategories },
      },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_END_OF_LIFE_CATEGORIES_WITH_ITEM_COUNT_BY_USER_ID,
      variables: {
        type: 'vault',
        userId: rootState.userId,
      },
    });
    commit('setEndOfLifeCategoryItemCounts', endOfLifeCategories);
  },
  increaseEndOfLifeCategoryItemCount({ commit }, categoryId) {
    commit('increaseEndOfLifeCategoryItemCount', categoryId);
  },
  decreaseEndOfLifeCategoryItemCount({ commit }, categoryId) {
    commit('decreaseEndOfLifeCategoryItemCount', categoryId);
  },
};
