import { getField } from 'vuex-map-fields';

export const state = () => ({
  showNewInstitutionModal: false,
});

export const getters = {
  getField,
};

export const mutations = {
  setShowNewInstitutionModal(state, value) {
    state.showNewInstitutionModal = value;
  },
};

export const actions = {
  openNewInstitutionModal({ commit }) {
    commit('setShowNewInstitutionModal', true);
  },
  closeNewInstitutionModal({ commit }) {
    commit('setShowNewInstitutionModal', false);
  },
};
